import {convertStyles} from "../../../services/utils";
import React, {useEffect, useRef, useState} from "react";
import useIsInViewport from "../../../hooks/useIsInViewPort";

interface IProps {
    item: {
        videoLink: string
        settings: Array<any>
        fullScreen: boolean

    }
}

interface styles {

    parentStyles: { [key: string]: string | number } | undefined,
    animationAos: { "data-aos": string },
    styles: {
        [key: string]: string
    }
}

const VideoComponent = (props: IProps) => {
    const {item} = props

    const [link, setLink] = useState("")
    const viewRef = useRef(null)
    const isInViewport = useIsInViewport(viewRef);
    const [needLoad, setNeedLoad] = useState(false)

    useEffect(() => {
        setLink(item.videoLink)
    }, [])

    useEffect(() => {
        if (isInViewport) {
            setNeedLoad(true)
        }
    }, [isInViewport])

    const setStyles: styles = convertStyles(item.settings) || {};

    return (


        <div
            data-aos={setStyles?.animationAos['data-aos']}
            style={{...setStyles?.parentStyles}}
            ref={viewRef}
        >
            {needLoad ?
                <iframe
                    src={link}
                    allow={"autoplay"}
                    width={"100%"}
                    height={setStyles?.styles?.height || "200px"}

                />
                :
                ""}
        </div>

    );
}


export default VideoComponent